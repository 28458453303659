import GatsbyLink from "gatsby-link"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import Layout from "../components/layout"
import { OBJLoader } from "three/addons/loaders/OBJLoader.js"
import SEO from "../components/seo"
import React, { useEffect, useRef, useState } from "react"
import { useFrame } from "@react-three/fiber"
import {
  AmbientLight,
  BufferGeometry,
  Float32BufferAttribute,
  Group,
  MathUtils,
  Mesh,
  MeshPhongMaterial,
  PerspectiveCamera,
  PointLight,
  Points,
  PointsMaterial,
  Scene,
  SphereGeometry,
  TextureLoader,
  WebGLRenderer,
} from "three"

const Box = props => {
  // This reference gives us direct access to the THREE.Mesh object
  const ref = useRef()
  // Hold state for hovered and clicked events
  const [hovered, hover] = useState(false)
  const [clicked, click] = useState(false)
  // Subscribe this component to the render-loop, rotate the mesh every frame
  useFrame((state, delta) => (ref.current.rotation.x += delta))
  // Return the view, these are regular Threejs elements expressed in JSX
  return (
    <mesh
      {...props}
      ref={ref}
      scale={clicked ? 1.5 : 1}
      onClick={event => click(!clicked)}
      onPointerOver={event => hover(true)}
      onPointerOut={event => hover(false)}
    >
      <boxGeometry args={[1, 1, 1]} />
      <meshStandardMaterial color={hovered ? "hotpink" : "orange"} />
    </mesh>
  )
}

const OBJECTS = {
  SPACE_STATION: "space_station",
  CRYOSAT: "cryosat",
  GPS: "gps",
  EARTH: "earth",
}

const gpsAltitute = 20200
const maximumCoord = 30
const cryosatAltitude = 717
const cryosatCoord = (cryosatAltitude * maximumCoord) / gpsAltitute
const spaceStationAltitude = 460
const spaceStationCoord = (spaceStationAltitude * maximumCoord) / gpsAltitute

const satellites = [
  { title: OBJECTS.CRYOSAT, altitude: cryosatCoord },
  { title: OBJECTS.GPS, altitude: maximumCoord },
  {
    title: OBJECTS.SPACE_STATION,
    altitude: spaceStationCoord,
  },
]

const cryosatVelocityX = 0.25
const cryosateVelocityY = -0.075
const cryosatVelocityZ = 7.48
const absoluteCryosatVelocity = Math.sqrt(
  cryosatVelocityX ** 2 + cryosateVelocityY ** 2 + cryosatVelocityZ ** 2
)

const gpsVelocityX = -1.63
const gpsVelocityY = -1.46
const gpsVelocityZ = 3.21
const absoluteGpsVelocity = Math.sqrt(
  gpsVelocityX ** 2 + gpsVelocityY ** 2 + gpsVelocityZ ** 2
)

const spaceStationVelocityX = -0.98
const spaceStationVelocityY = -7.47
const spaceStationVelocityZ = 1.36
const absoluteSpaceStationVelocity = Math.sqrt(
  spaceStationVelocityX ** 2 +
    spaceStationVelocityY ** 2 +
    spaceStationVelocityZ ** 2
)

class ObjectGroup {
  constructor(index, altitude) {
    const objectGroup = new Group()

    ObjectGroup.createSatelliteObject(satellite => {
      switch (index) {
        case 1:
          satellite.traverse(child => {
            if (child instanceof Mesh) {
              child.material.color.set(0x009c68)
            }
          })
          satellite.scale.set(0.6, 0.6, 0.6)

          satellite.position.x = altitude * 10
          satellite.position.y = 0
          satellite.position.z = 0
          break
        case 2:
          satellite.scale.set(0.7, 0.7, 0.7)

          satellite.position.x = -altitude / 2
          satellite.position.y = altitude
          satellite.position.z = altitude
          break
        case 3:
          satellite.traverse(child => {
            if (child instanceof Mesh) {
              child.material.color.set(0x878a00)
            }
          })
          satellite.scale.set(0.5, 0.5, 0.5)

          satellite.position.x = 0
          satellite.position.y = 0
          satellite.position.z = altitude / 3
          break
        default:
          break
      }

      satellite.position.x += 8 * index
      objectGroup.add(satellite)
    })

    return objectGroup
  }

  static createPlanetObject = (title, objectGeometry) => {
    const objectTexture = new TextureLoader().load(`/images/${title}.jpg`)
    const objectMaterial = new MeshPhongMaterial({ map: objectTexture })
    const objectMesh = new Mesh(objectGeometry, objectMaterial)

    return objectMesh
  }

  static createSatelliteObject = onComplete => {
    // Load the satellite model
    const loader = new OBJLoader()

    loader.load("/objects/Satellite.obj", function (object) {
      onComplete(object)
    })
  }
}

const OrbitPage = () => {
  useEffect(() => {
    const scene = new Scene()
    const camera = new PerspectiveCamera(
      30,
      window.innerWidth / window.innerHeight
    )

    camera.position.z = 180

    const renderer = new WebGLRenderer()

    renderer.setSize(window.innerWidth, window.innerHeight)
    renderer.setPixelRatio(window.devicePixelRatio)

    const rootElement = document.getElementById("root")

    if (!rootElement.hasChildNodes()) {
      rootElement.appendChild(renderer.domElement)
    }

    const ambientLight = new AmbientLight()
    const pointLight = new PointLight(0xffffff, 1)

    pointLight.position.set(0, 0, 0)

    scene.add(ambientLight, pointLight)

    const starsCoords = []

    for (let i = 0; i < 10000; i++) {
      const x = MathUtils.randFloatSpread(1000)
      const y = MathUtils.randFloatSpread(1000)
      const z = MathUtils.randFloatSpread(1000)

      starsCoords.push(x, y, z)
    }

    const starsGeometry = new BufferGeometry()

    starsGeometry.setAttribute(
      "position",
      new Float32BufferAttribute(starsCoords, 3)
    )

    const starsMaterial = new PointsMaterial({ color: "green" })
    const stars = new Points(starsGeometry, starsMaterial)

    scene.add(stars)

    const earth = ObjectGroup.createPlanetObject(
      OBJECTS.EARTH,
      new SphereGeometry(15, 64, 32)
    )
    scene.add(earth)

    // we need primary, secondary and third object to rotate satellites around them, so around the earth
    // These two objects has dimmensions set to 1 to take up as little space as possible
    const primaryObject = ObjectGroup.createPlanetObject(
      OBJECTS.EARTH,
      new SphereGeometry(1, 1, 1)
    )
    scene.add(primaryObject)

    const secondaryObject = ObjectGroup.createPlanetObject(
      OBJECTS.EARTH,
      new SphereGeometry(1, 1, 1)
    )
    scene.add(secondaryObject)

    const thrirdObject = ObjectGroup.createPlanetObject(
      OBJECTS.EARTH,
      new SphereGeometry(1, 1, 1)
    )
    scene.add(thrirdObject)

    const satellitesMap = new Map()

    for (let [index, { title, radius, altitude }] of satellites.entries()) {
      const planetGroup = new ObjectGroup(index + 1, altitude)

      satellitesMap.set(title, planetGroup)

      switch (index) {
        case 0:
          primaryObject.add(planetGroup)
          break
        case 1:
          secondaryObject.add(planetGroup)
          break
        case 2:
          thrirdObject.add(planetGroup)
          break
        default:
          break
      }
    }

    const animate = () => {
      // Rotate Earth and other objects around their axis to rotate the satellites around them
      earth.rotation.y += 0.001
      primaryObject.rotation.y += absoluteCryosatVelocity / 1000
      secondaryObject.rotation.x += absoluteGpsVelocity / 1000
      thrirdObject.rotation.z += absoluteSpaceStationVelocity / 1000

      // Small rotation of satellites around their axis to show whole 3d model
      const firstSatellite = satellitesMap.get(OBJECTS.CRYOSAT)
      firstSatellite.rotation.x += 0.005

      const thirdSallite = satellitesMap.get(OBJECTS.SPACE_STATION)
      thirdSallite.rotation.x += 0.005

      renderer.render(scene, camera)

      requestAnimationFrame(animate)
    }

    animate()
  }, [])

  return (
    <Layout>
      <SEO title="Alexandros Kazantzidis" />

      <div className="relative">
        <div className="py-8 sm:py-4 w-full sm:w-1/4 bg-slate-900 relative sm:absolute top-0 sm:top-10 left-0 sm:left-10 sm:border border-gray-700  sm:rounded-xl text-white p-4 flex flex-col gap-4">
          <div className="flex justify-center relative">
            <GatsbyLink
              className="text-white font-semibold text-lg absolute left-0"
              to="/"
            >
              <FontAwesomeIcon color="white" icon={faArrowLeft} size="1x" />
            </GatsbyLink>

            <h2 className="font-semibold text-center sm:text-start">
              Orbit Determinator
            </h2>
          </div>

          <h3 className="">
            Open source project implemented during Google summer of code 2017
          </h3>

          <p>
            Github repo:{" "}
            <a
              className="text-blue-600"
              href="https://github.com/aerospaceresearch/orbitdeterminator"
              target="_blank"
            >
              Orbit Determinator
            </a>
          </p>

          <p>
            Tech stack used:{" "}
            <span className="font-semibold">
              Python, numpy, astropy, pytest, sphinx
            </span>
          </p>

          <p>
            Oh, and that mesmerizing visualization you're admiring? It's powered
            by our very own Python lib, calculating altitudes and velocities
            with cosmic precision.{" "}
            <span className="text-teal-400">Fun fact</span>: Ever noticed how
            the GPS satellite, reigning high above, moves at a leisurely pace,
            twice as slow as its celestial comrades? Just another quirk in our
            cosmic dance!
          </p>
        </div>

        <div id="root" />

        <div className="w-full sm:w-1/4 bg-slate-900 sm:bg-transparent relative sm:absolute top-0 sm:top-10 right-0 sm:right-10 text-white p-4 flex flex-col gap-4">
          <div className="border border-[#878a00] text-[#878a00] rounded-xl p-4 flex flex-col gap-2 cursor-default">
            <h3>Cryosat-2</h3>

            <p className="text-sm">
              Altitude: {cryosatAltitude} km <br />
              Velocity: {absoluteCryosatVelocity.toFixed(2)} km/s
            </p>
          </div>

          <div className="border border-[#009c68] text-[#009c68] rounded-xl p-4 flex flex-col gap-2 cursor-default">
            <h3>International Space Station (ISS-ZARYA)</h3>

            <p className="text-sm">
              Altitude: {spaceStationAltitude} km <br />
              Velocity: {absoluteSpaceStationVelocity.toFixed(2)} km/s
            </p>
          </div>

          <div className="border border-white rounded-xl p-4 flex flex-col gap-2 cursor-default">
            <h3>GPS BIIR-2 (PRN 13)</h3>

            <p className="text-sm">
              Altitude: {gpsAltitute} km <br />
              Velocity: {absoluteGpsVelocity.toFixed(2)} km/s
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default OrbitPage
